/* eslint-disable no-loop-func */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import { gsap } from "gsap";

export default function eyeball(page, container, eye, marker, marker2){

    if (page === null || container === null || eye === null) return
    let pagePos = page.getBoundingClientRect()
    let containPos = container.getBoundingClientRect()
    let eyeballPos = eye.getBoundingClientRect()
    // let markerPos = marker.getBoundingClientRect()
    let movementLimiter = .8

    let menu = document.getElementById('menu_container')
    let overlay = document.getElementById('menu_overlay_container')
    let menuOptionsContainer = document.getElementById('menu_options_container')

    let hoverElements = [page, menu, overlay, menuOptionsContainer]

    let center = {
        x: containPos.x + containPos.width / 2,
        y: containPos.y + containPos.height / 2
       }

//     let center = {
//         x: eyeballPos.x + eyeballPos.width / 2,
//         y: eyeballPos.y + eyeballPos.height / 2
//     }


// let hor = eyeballPos.left - markerPos.left + (eyeballPos.width/2) - (markerPos.width/2)
// let vert = eyeballPos.top - markerPos.top + (eyeballPos.height / 2) - (markerPos.height/2)

// let hor = containPos.left - markerPos.left + (containPos.width/2) - (markerPos.width/2)
// let vert = containPos.top - markerPos.top + (containPos.height / 2) - (markerPos.height/2)

// gsap.set(marker, {x: hor, y: vert})
// gsap.set(marker2, {x: hor, y: vert})

    const updateCenter = () => {
    if (container === null ) return
    containPos = container.getBoundingClientRect()
    center.x = containPos.x + containPos.width / 2
    center.y = containPos.y + containPos.height / 2
    }

    window.addEventListener('resize', updateCenter)

    let mouseCords = { x: 0, y: 0 };
    let eyeCords = {x: 0, y: 0}
    let mouseDistance = 0;
    let innerDistance = 0
    let outerDistance = 0
    let proportion
    let angle = 0
    let slope
    let maxInnerDistance = Math.sqrt(((containPos.width / 2) * (containPos.width / 2) + (containPos.height / 2) * (containPos.height / 2)))

    const distanceFormula = (x1, x2, y1, y2) => {
        let sqrXDiff = Math.pow((x2 - x1), 2)
        let sqrYDiff = Math.pow((y2 - y1), 2)
        let sumOfSqrs = sqrXDiff + sqrYDiff
        let distance = Math.sqrt(sumOfSqrs)
        return distance
      }

    function calcAngleDegrees(x, y) {
    let angle = Math.atan2(y, x) * 180 / Math.PI;
    if (angle < 0) angle = 360 + angle
    return angle
    }

    function calcY(slope, x){
        let y = (slope * (x - center.x)) + center.y
        eyeCords.x = x
        eyeCords.y = y
        innerDistance = distanceFormula(center.x, x, center.y, y)
    }

    function calcX(slope, y){
    let x = ((y - center.y) / slope) + center.x
    eyeCords.x = x
    eyeCords.y = y
        innerDistance = distanceFormula(center.x, x, center.y, y)
    }

    function calcOuterY(slope, x){
        let y = (slope * (x - center.x)) + center.y
        outerDistance = distanceFormula(center.x, x, center.y, y)
    }

    function calcOuterX(slope, y){
    let x = ((y - center.y) / slope) + center.x
    outerDistance = distanceFormula(center.x, x, center.y, y)
    }

    function findInnerLength(x1, x2, y1, y2){

        let corner = {x: containPos.x + containPos.width, y: containPos.y + containPos.height}
        let angleX = corner.x - center.x
        let angleY = corner.y - center.y
        let angle1 = calcAngleDegrees(angleX, angleY)
        // let angle2 = 180 - angle1
        slope = (y2 - y1)/(x2 - x1)
        
        //first quadrant
        if(angle >= 360 - angle1 || angle < angle1 ){
        //   console.log('you are in first quadrant')
          //we know our x2 value, we don't know y2
          let x = containPos.x + containPos.width
          calcY(slope, x)
        }
        
        //second quadrant
        if(angle < 360 - angle1 && angle >= 180 + angle1 ){
          // console.log('you are in the second quadarnt')
          //we know our y2 value, we don't know x2
          let y = containPos.y
          calcX(slope, y)
        }
        
        //third quadrant
        if(angle < 180 + angle1 && angle >= 180 - angle1 ){
          // console.log('you are in third quadrant')
          //we know our x2 value, we don't know y2
          let x = containPos.x
          calcY(slope, x)
        }
        
        //fourth quadrant 
        if(angle < 180 - angle1 && angle >= angle1){
          // console.log('you are in the fourth quadrant')
          //we know our y2 value, we don't know x2
          let y = containPos.y + containPos.height
          calcX(slope, y)
        }
        
      }
      
      function findOuterLength(x1, x2, y1, y2){
      
        let corner1 = {x: pagePos.x + pagePos.width, y: pagePos.y + pagePos.height}
        let angle1X = corner1.x - center.x
        let angle1Y = corner1.y - center.y
        let angle1 = calcAngleDegrees(angle1X, angle1Y)

        let corner2 = {x: pagePos.x + pagePos.width, y: 0}
        let angle2X = corner2.x - center.x
        let angle2Y = corner2.y - center.y
        let angle2 = calcAngleDegrees(angle2X, angle2Y)

        let corner3 = {x: 0, y: 0}
        let angle3X = corner3.x - center.x
        let angle3Y = corner3.y - center.y
        let angle3 = calcAngleDegrees(angle3X, angle3Y)

        let corner4 = {x: 0, y: pagePos.y + pagePos.height}
        let angle4X = corner4.x - center.x
        let angle4Y = corner4.y - center.y
        let angle4 = calcAngleDegrees(angle4X, angle4Y)
        
        //first quadrant
        if (angle >= angle2 || angle < angle1 ){
        //   console.log('you are in first quadrant')
        //   console.log('ANGLE 2', angle2)
          //we know our x2 value, we don't know y2
          let x = pagePos.x + pagePos.width
          calcOuterY(slope, x)
        }
        
        //second quadrant
        if (angle < angle2 && angle >= angle3){
        //   console.log('you are in the second quadarnt')
        //   console.log('ANGLE 3', angle3)
          //we know our y2 value, we don't know x2
          let y = pagePos.y
          calcOuterX(slope, y)
        }
        
        //third quadrant
        if (angle < angle3 && angle >= angle4){
        //   console.log('you are in third quadrant')
        // console.log('ANGLE 4', angle4)
          //we know our x2 value, we don't know y2
          let x = pagePos.x
          calcOuterY(slope, x)
        }
        
        //fourth quadrant
        if (angle < angle4 && angle >= angle1){
        //   console.log('you are in the fourth quadrant')
          //we know our y2 value, we don't know x2
          let y = pagePos.y + pagePos.height
          calcOuterX(slope, y)
        }
        
      }


    //   page.addEventListener('mousemove', (e) => {
  
    //     mouseCords.x = e.clientX
    //     mouseCords.y = e.clientY
        
    //     let x1 = center.x
    //     let y1 = center.y
        
    //     let x2 = mouseCords.x
    //     let y2 = mouseCords.y
        
    //     let angleX = x2 - x1
    //     let angleY = y2 - y1
        
    //     mouseDistance = distanceFormula(x1, x2, y1, y2)
    //     findInnerLength(x1, x2, y1, y2)
    //     findOuterLength(x1, x2, y1, y2)
    //     angle = calcAngleDegrees(angleX, angleY)
        
    //     proportion = mouseDistance / outerDistance
    //     // console.log('this is proportion', proportion)
    //     // console.log('outerDistance', outerDistance)
        
    //     let proportionalInnerX = (eyeCords.x - center.x) * proportion * movementLimiter
    //     let proportionalInnerY = (eyeCords.y - center.y) * proportion * movementLimiter
      
    //     if (innerDistance > maxInnerDistance) return
    //     gsap.to(eye, .01, {x: proportionalInnerX, y: proportionalInnerY,  transformOrigin: '50% 50%'})
    //     // gsap.to(marker, .01, {width: innerDistance, rotation: angle, transformOrigin: '0% 50%'})
    //     // gsap.to(marker2, .01, {width: outerDistance/4, rotation: angle, transformOrigin: '0% 50%'})
    //   })

    for ( let i = 0; i < hoverElements.length; i++){
        hoverElements[i].addEventListener('mousemove', (e) => {
  
            mouseCords.x = e.clientX
            mouseCords.y = e.clientY
            
            let x1 = center.x
            let y1 = center.y
            
            let x2 = mouseCords.x
            let y2 = mouseCords.y
            
            let angleX = x2 - x1
            let angleY = y2 - y1
            
            mouseDistance = distanceFormula(x1, x2, y1, y2)
            findInnerLength(x1, x2, y1, y2)
            findOuterLength(x1, x2, y1, y2)
            angle = calcAngleDegrees(angleX, angleY)
            
            proportion = mouseDistance / outerDistance
            // console.log('this is proportion', proportion)
            // console.log('outerDistance', outerDistance)
            
            let proportionalInnerX = (eyeCords.x - center.x) * proportion * movementLimiter
            let proportionalInnerY = (eyeCords.y - center.y) * proportion * movementLimiter
          
            if (innerDistance > maxInnerDistance) return
            gsap.to(eye, .25, {ease: 'Power1.easeOut', x: proportionalInnerX, y: proportionalInnerY,  transformOrigin: '50% 50%'})
            // gsap.to(marker, .01, {width: innerDistance, rotation: angle, transformOrigin: '0% 50%'})
            // gsap.to(marker2, .01, {width: outerDistance/4, rotation: angle, transformOrigin: '0% 50%'})
          })
    }

      // document.addEventListener('mouseleave', () => {
      //     gsap.to(eye, 1, {x: 0, y: 0, ease: 'Power4.easeOut',  transformOrigin: '50% 50%'})
      // })



}
