import React, {useState, useRef, useEffect} from 'react'
import { gsap } from "gsap";

export const Header = (props) => {

    const svgStyle = {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: '2'
    }

    //style={styleA}
    const styleA = {
        fill: 'rgb(214,39,50)'
    }

    //{styleB} 
    const styleB = {
        fill: 'rgb(1,123,179)',
        fillRule: 'nonzero'
    }

    let arrow = useRef(null)

    const [children, setChildren] = useState(null)
    
    useEffect(() => {
        setChildren(arrow.getElementsByTagName('path'))
    })

    const test = () => {
        gsap.to(children, 1, {ease: 'Power1.easeOut', fill: 'rgb(1,123,179)'})
    }


      return (
<svg width="100%" height="100%" viewBox="0 0 2048 115" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" xmlnsserif="http://www.serif.com/" style={svgStyle}>
    <g id="Header"
        ref={el => {arrow = el}}
        onMouseEnter={() => {test()}}>
        <g transform="matrix(2.38038,0,0,0.798069,-508.903,-36.8787)">
            <path d="M213.791,143.2L213.791,92.511L523.996,92.511L519.071,46.21L543.092,117.855L519.071,189.501L523.996,143.2L213.791,143.2Z" style={styleA} />
        </g>
        <g transform="matrix(-2.38038,2.91512e-16,-9.77353e-17,-0.798069,2556.9,151.235)">
            <path d="M213.791,143.2L213.791,92.511L523.996,92.511L519.071,46.21L543.092,117.855L519.071,189.501L523.996,143.2L213.791,143.2Z" style={styleA} />
        </g>
        <g transform="matrix(0.476104,0,0,0.909323,436.567,-25.6826)">
            <g transform="matrix(119.754,0,0,119.754,773.801,130.84)">
                <path d="M0.047,-0.667L0.047,-0L0.25,-0L0.25,-0.231L0.727,-0.231L0.727,-0.384L0.25,-0.384L0.25,-0.51L0.752,-0.51L0.752,-0.667L0.047,-0.667Z" style={styleB} />
            </g>
            <g transform="matrix(119.754,0,0,119.754,863.736,130.84)">
                <path d="M0.043,-0.667L0.043,-0.36C0.044,-0.252 0.044,-0.252 0.045,-0.23C0.05,-0.132 0.061,-0.091 0.094,-0.057C0.144,-0.004 0.224,0.01 0.479,0.01C0.634,0.01 0.701,0.006 0.761,-0.006C0.837,-0.022 0.888,-0.075 0.899,-0.151C0.905,-0.198 0.906,-0.209 0.909,-0.36L0.909,-0.667L0.706,-0.667L0.706,-0.36C0.706,-0.346 0.705,-0.314 0.704,-0.291C0.701,-0.228 0.696,-0.21 0.68,-0.193C0.658,-0.169 0.616,-0.163 0.476,-0.163C0.303,-0.163 0.264,-0.174 0.253,-0.227C0.248,-0.254 0.248,-0.255 0.246,-0.36L0.246,-0.667L0.043,-0.667Z" style={styleB} />
            </g>
            <g transform="matrix(119.754,0,0,119.754,977.861,130.84)">
                <path d="M0.047,-0.667L0.047,-0L0.734,-0L0.734,-0.17L0.26,-0.17L0.26,-0.667L0.047,-0.667Z" style={styleB} />
            </g>
            <g transform="matrix(119.754,0,0,119.754,1065.76,130.84)">
                <path d="M0.047,-0.667L0.047,-0L0.734,-0L0.734,-0.17L0.26,-0.17L0.26,-0.667L0.047,-0.667Z" style={styleB} />
            </g>
            <g transform="matrix(119.754,0,0,119.754,1156.17,130.84)">
                <path d="M0.023,-0.221L0.023,-0.189C0.023,-0.097 0.038,-0.058 0.084,-0.029C0.131,0.001 0.212,0.01 0.419,0.01C0.618,0.01 0.702,0.005 0.742,-0.01C0.811,-0.036 0.839,-0.093 0.839,-0.209C0.839,-0.302 0.822,-0.347 0.776,-0.377C0.733,-0.404 0.69,-0.409 0.47,-0.416C0.329,-0.42 0.274,-0.423 0.256,-0.428C0.235,-0.433 0.226,-0.446 0.226,-0.471C0.226,-0.52 0.24,-0.524 0.412,-0.524C0.556,-0.524 0.581,-0.522 0.598,-0.512C0.613,-0.503 0.616,-0.494 0.618,-0.457L0.814,-0.457C0.815,-0.47 0.815,-0.484 0.815,-0.488C0.815,-0.567 0.799,-0.609 0.759,-0.636C0.712,-0.668 0.642,-0.677 0.448,-0.677C0.288,-0.677 0.196,-0.673 0.157,-0.665C0.065,-0.645 0.029,-0.588 0.029,-0.461C0.029,-0.314 0.075,-0.272 0.24,-0.267L0.294,-0.265L0.402,-0.26L0.562,-0.254C0.627,-0.253 0.642,-0.243 0.642,-0.203C0.642,-0.144 0.642,-0.144 0.424,-0.144C0.281,-0.144 0.259,-0.146 0.24,-0.158C0.224,-0.168 0.22,-0.181 0.22,-0.221L0.023,-0.221Z" style={styleB} />
            </g>
            <g transform="matrix(119.754,0,0,119.754,1260.48,130.84)">
                <path d="M0,-0.667L0,-0.497L0.297,-0.497L0.297,-0L0.51,-0L0.51,-0.497L0.807,-0.497L0.807,-0.667L0,-0.667Z" style={styleB} />
            </g>
            <g transform="matrix(119.754,0,0,119.754,1346.46,130.84)">
                <path d="M0.77,-0L1.001,-0L0.652,-0.667L0.336,-0.667L-0.017,-0L0.215,-0L0.272,-0.113L0.713,-0.113L0.77,-0ZM0.642,-0.259L0.344,-0.259L0.473,-0.512L0.513,-0.512L0.642,-0.259Z" style={styleB} />
            </g>
            <g transform="matrix(119.754,0,0,119.754,1463.58,130.84)">
                <path d="M0.68,-0.257C0.679,-0.158 0.676,-0.157 0.453,-0.157C0.239,-0.157 0.236,-0.159 0.236,-0.33C0.236,-0.431 0.244,-0.47 0.268,-0.49C0.288,-0.506 0.325,-0.51 0.452,-0.51C0.58,-0.51 0.639,-0.504 0.654,-0.491C0.666,-0.481 0.668,-0.472 0.67,-0.428L0.873,-0.428L0.873,-0.461C0.873,-0.568 0.853,-0.617 0.796,-0.646C0.75,-0.67 0.688,-0.677 0.51,-0.677C0.161,-0.677 0.088,-0.66 0.052,-0.57C0.037,-0.533 0.033,-0.478 0.033,-0.329C0.033,-0.244 0.035,-0.189 0.038,-0.159C0.046,-0.091 0.066,-0.054 0.11,-0.029C0.164,0.002 0.248,0.01 0.509,0.01C0.657,0.01 0.73,0.004 0.784,-0.013C0.858,-0.037 0.886,-0.086 0.886,-0.194C0.886,-0.204 0.885,-0.219 0.884,-0.257L0.68,-0.257Z" style={styleB} />
            </g>
            <g transform="matrix(119.754,0,0,119.754,1572.32,130.84)">
                <path d="M0.047,-0.667L0.047,-0L0.25,-0L0.25,-0.255L0.361,-0.255L0.66,-0L0.968,-0L0.55,-0.34L0.936,-0.671L0.625,-0.671L0.361,-0.425L0.25,-0.425L0.25,-0.667L0.047,-0.667Z" style={styleB} />
            </g>
        </g>
    </g>
</svg>
    )
}
