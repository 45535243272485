import React, {useEffect, useRef, useState} from 'react'
import { gsap } from "gsap";

export const Footer = (props) => {

    const svgStyle = {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: '2'
    }

    // {styleA} 
    const styleA = {
        fill: 'rgb(1,123,179)',
        fillRule: 'nonnzero',
        cursor: 'pointer'
    }

    //{styleB} 
    const styleB = {
        fill: 'rgb(239,162,38)',
        fillRule: 'nonzero',
        cursor: 'pointer'
    }

    const styleC = {
        fill: 'transparent',
        cursor: 'pointer'
    }

    let link1 = useRef(null)
    let link2 = useRef(null)
    let link3 = useRef(null)

    const [link1Children, setLink1Children] = useState(null)
    const [misc, setMisc] = useState(null)
    const [link2Children, setLink2Children] = useState(null)
    const [link3Children, setLink3Children] = useState(null)

    useEffect(() => {
      setLink1Children(link1.getElementsByTagName('path'))
      setLink2Children(link2.getElementsByTagName('path'))
      setLink3Children(link3.getElementsByTagName('path'))
      setMisc(link1.getElementsByTagName('rect'))
  })

    const mouseEnter1 = () => {

        gsap.to([link1Children, misc], 1, {ease: 'Power4.easeOut', fill: '#ffffff'})

    }

    const mouseEnter2 = () => {

        gsap.to(link2Children, 1, {ease: 'Power4.easeOut', fill: '#ffffff'})
    }

    const mouseEnter3 = () => {

        gsap.to(link3Children, 1, {ease: 'Power4.easeOut', fill: '#ffffff'})
    }

    const mouseLeave1 = () => {

        gsap.to([link1Children, misc, link2Children, link3Children], 1, {ease: 'Power4.easeOut', fill: styleB.fill})

    }

      return (
        <svg width="100%" height="100%" viewBox="0 0 1875 543" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" xmlnsserif="http://www.serif.com/" style={svgStyle} >
            <g transform="matrix(1,0,0,1,-96,-1451)">
                <g>
                    <g transform="matrix(0.586358,0,0,1.04336,-15.9332,-130.872)">
                        <g transform="matrix(111.719,0,0,111.719,188.822,1601.73)">
                            <rect x="0.047" y="-0.667" width="0.213" height="0.667" style={styleA} />
                        </g>
                        <g transform="matrix(111.719,0,0,111.719,223.12,1601.73)">
                            <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.189C0.217,-0.276 0.221,-0.298 0.243,-0.321C0.264,-0.343 0.299,-0.352 0.364,-0.352C0.43,-0.352 0.464,-0.345 0.483,-0.326C0.502,-0.308 0.505,-0.291 0.505,-0.215L0.505,-0L0.682,-0L0.682,-0.215C0.682,-0.345 0.673,-0.381 0.63,-0.426C0.588,-0.469 0.53,-0.485 0.417,-0.485C0.302,-0.485 0.248,-0.462 0.216,-0.401L0.207,-0.401L0.207,-0.473L0.04,-0.473Z" style={styleA} />
                        </g>
                        <g transform="matrix(111.719,0,0,111.719,303.446,1601.73)">
                            <path d="M0.494,-0.19C0.486,-0.136 0.456,-0.122 0.352,-0.122C0.305,-0.122 0.267,-0.127 0.246,-0.136C0.214,-0.149 0.204,-0.173 0.204,-0.235C0.204,-0.334 0.227,-0.353 0.35,-0.353C0.454,-0.353 0.489,-0.335 0.495,-0.281L0.675,-0.281C0.672,-0.37 0.659,-0.407 0.621,-0.437C0.576,-0.474 0.507,-0.486 0.35,-0.486C0.19,-0.486 0.128,-0.475 0.083,-0.438C0.041,-0.404 0.027,-0.354 0.027,-0.24C0.027,-0.115 0.042,-0.064 0.088,-0.032C0.135,0.002 0.196,0.012 0.341,0.012C0.527,0.012 0.585,-0 0.63,-0.046C0.661,-0.077 0.671,-0.111 0.674,-0.19L0.494,-0.19Z" style={styleA} />
                        </g>
                        <g transform="matrix(111.719,0,0,111.719,381.538,1601.73)">
                            <rect x="0.04" y="-0.667" width="0.177" height="0.667" style={styleA} />
                        </g>
                        <g transform="matrix(111.719,0,0,111.719,410.25,1601.73)">
                            <path d="M0.678,-0L0.678,-0.473L0.501,-0.473L0.501,-0.283C0.501,-0.195 0.495,-0.17 0.47,-0.148C0.448,-0.129 0.416,-0.122 0.353,-0.122C0.286,-0.122 0.259,-0.128 0.238,-0.148C0.219,-0.166 0.214,-0.188 0.214,-0.257L0.214,-0.473L0.037,-0.473L0.037,-0.257C0.037,-0.128 0.046,-0.092 0.089,-0.048C0.132,-0.005 0.19,0.012 0.3,0.012C0.419,0.012 0.475,-0.011 0.502,-0.071L0.511,-0.071L0.511,-0L0.678,-0Z" style={styleA} />
                        </g>
                        <g transform="matrix(111.719,0,0,111.719,490.353,1601.73)">
                            <path d="M0.693,-0.667L0.516,-0.667L0.516,-0.41L0.509,-0.41C0.475,-0.47 0.434,-0.486 0.31,-0.486C0.188,-0.486 0.139,-0.478 0.096,-0.45C0.046,-0.417 0.027,-0.36 0.027,-0.24C0.027,-0.122 0.039,-0.074 0.078,-0.038C0.119,-0.001 0.18,0.012 0.308,0.012C0.436,0.012 0.483,-0.005 0.517,-0.062L0.526,-0.062L0.526,-0L0.693,-0L0.693,-0.667ZM0.368,-0.353C0.494,-0.353 0.517,-0.335 0.517,-0.237C0.517,-0.182 0.51,-0.157 0.49,-0.144C0.47,-0.13 0.422,-0.122 0.357,-0.122C0.224,-0.122 0.204,-0.138 0.204,-0.242C0.204,-0.337 0.228,-0.353 0.368,-0.353Z" style={styleA} />
                        </g>
                        <g transform="matrix(111.719,0,0,111.719,572.243,1601.73)">
                            <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.473L0.04,-0.473ZM0.04,-0.667L0.04,-0.534L0.217,-0.534L0.217,-0.667L0.04,-0.667Z" style={styleA} />
                        </g>
                        <g transform="matrix(111.719,0,0,111.719,600.955,1601.73)">
                            <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.189C0.217,-0.276 0.221,-0.298 0.243,-0.321C0.264,-0.343 0.299,-0.352 0.364,-0.352C0.43,-0.352 0.464,-0.345 0.483,-0.326C0.502,-0.308 0.505,-0.291 0.505,-0.215L0.505,-0L0.682,-0L0.682,-0.215C0.682,-0.345 0.673,-0.381 0.63,-0.426C0.588,-0.469 0.53,-0.485 0.417,-0.485C0.302,-0.485 0.248,-0.462 0.216,-0.401L0.207,-0.401L0.207,-0.473L0.04,-0.473Z" style={styleA} />
                        </g>
                        <g transform="matrix(111.719,0,0,111.719,681.281,1601.73)">
                            <path d="M0.527,-0.473L0.527,-0.411L0.519,-0.411C0.482,-0.47 0.44,-0.485 0.317,-0.485C0.228,-0.485 0.162,-0.478 0.126,-0.463C0.052,-0.434 0.027,-0.381 0.027,-0.252C0.027,-0.133 0.041,-0.085 0.087,-0.049C0.128,-0.018 0.19,-0.006 0.315,-0.006C0.433,-0.006 0.475,-0.022 0.511,-0.08L0.518,-0.08L0.518,-0.038C0.518,0.028 0.515,0.043 0.498,0.062C0.477,0.086 0.443,0.093 0.35,0.093C0.257,0.093 0.231,0.084 0.221,0.048L0.219,0.043L0.039,0.043C0.044,0.1 0.059,0.136 0.091,0.164C0.136,0.203 0.197,0.216 0.347,0.216C0.459,0.216 0.526,0.209 0.578,0.191C0.625,0.175 0.663,0.138 0.679,0.093C0.693,0.054 0.694,0.041 0.694,-0.038L0.694,-0.473L0.527,-0.473ZM0.367,-0.352C0.419,-0.352 0.459,-0.347 0.48,-0.338C0.508,-0.325 0.517,-0.303 0.517,-0.246C0.517,-0.197 0.511,-0.177 0.492,-0.163C0.472,-0.148 0.427,-0.14 0.358,-0.14C0.23,-0.14 0.204,-0.158 0.204,-0.246C0.204,-0.335 0.23,-0.352 0.367,-0.352Z" style={styleA} />
                        </g>
                        <g transform="matrix(111.719,0,0,111.719,188.822,1707.63)">
                            <path d="M0.023,-0.221L0.023,-0.189C0.023,-0.097 0.038,-0.058 0.084,-0.029C0.131,0.001 0.212,0.01 0.419,0.01C0.618,0.01 0.702,0.005 0.742,-0.01C0.811,-0.036 0.839,-0.093 0.839,-0.209C0.839,-0.302 0.822,-0.347 0.776,-0.377C0.733,-0.404 0.69,-0.409 0.47,-0.416C0.329,-0.42 0.274,-0.423 0.256,-0.428C0.235,-0.433 0.226,-0.446 0.226,-0.471C0.226,-0.52 0.24,-0.524 0.412,-0.524C0.556,-0.524 0.581,-0.522 0.598,-0.512C0.613,-0.503 0.616,-0.494 0.618,-0.457L0.814,-0.457C0.815,-0.47 0.815,-0.484 0.815,-0.488C0.815,-0.567 0.799,-0.609 0.759,-0.636C0.712,-0.668 0.642,-0.677 0.448,-0.677C0.288,-0.677 0.196,-0.673 0.157,-0.665C0.065,-0.645 0.029,-0.588 0.029,-0.461C0.029,-0.314 0.075,-0.272 0.24,-0.267L0.294,-0.265L0.402,-0.26L0.562,-0.254C0.627,-0.253 0.642,-0.243 0.642,-0.203C0.642,-0.144 0.642,-0.144 0.424,-0.144C0.281,-0.144 0.259,-0.146 0.24,-0.158C0.224,-0.168 0.22,-0.181 0.22,-0.221L0.023,-0.221Z" style={styleA} />
                        </g>
                        <g transform="matrix(111.719,0,0,111.719,285.236,1707.63)">
                            <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.195C0.217,-0.273 0.223,-0.303 0.243,-0.323C0.263,-0.343 0.298,-0.352 0.351,-0.352C0.402,-0.352 0.433,-0.344 0.449,-0.326C0.466,-0.308 0.468,-0.295 0.468,-0.221L0.468,-0L0.645,-0L0.645,-0.195C0.645,-0.272 0.651,-0.3 0.671,-0.322C0.69,-0.343 0.723,-0.352 0.776,-0.352C0.828,-0.352 0.858,-0.344 0.876,-0.326C0.893,-0.309 0.895,-0.3 0.896,-0.221L0.896,-0L1.073,-0L1.073,-0.221C1.073,-0.343 1.064,-0.382 1.023,-0.424C0.982,-0.467 0.924,-0.485 0.826,-0.485C0.71,-0.485 0.652,-0.457 0.622,-0.386C0.584,-0.456 0.521,-0.485 0.407,-0.485C0.302,-0.485 0.242,-0.459 0.216,-0.402L0.207,-0.402L0.207,-0.473L0.04,-0.473Z" style={styleA} />
                        </g>
                        <g transform="matrix(111.719,0,0,111.719,409.133,1707.63)">
                            <path d="M0.221,-0.325C0.226,-0.368 0.239,-0.372 0.366,-0.372C0.473,-0.372 0.487,-0.362 0.487,-0.288L0.487,-0.263L0.479,-0.263C0.451,-0.293 0.427,-0.3 0.345,-0.3C0.215,-0.3 0.169,-0.297 0.126,-0.285C0.057,-0.267 0.025,-0.22 0.025,-0.135C0.025,-0.023 0.074,0.012 0.231,0.012C0.346,0.012 0.391,0.008 0.42,-0.004C0.452,-0.017 0.471,-0.034 0.486,-0.065L0.493,-0.065L0.493,-0L0.663,-0L0.663,-0.285C0.663,-0.371 0.653,-0.407 0.623,-0.439C0.583,-0.479 0.547,-0.486 0.392,-0.486C0.25,-0.486 0.168,-0.477 0.127,-0.458C0.072,-0.431 0.049,-0.394 0.044,-0.325L0.221,-0.325ZM0.339,-0.2C0.414,-0.2 0.442,-0.199 0.453,-0.195C0.473,-0.188 0.484,-0.174 0.484,-0.155C0.484,-0.113 0.449,-0.101 0.324,-0.101C0.229,-0.101 0.202,-0.112 0.202,-0.15C0.202,-0.189 0.231,-0.2 0.339,-0.2Z" style={styleA} />
                        </g>
                        <g transform="matrix(111.719,0,0,111.719,487.336,1707.63)">
                            <path d="M0.025,-0.151C0.026,-0.096 0.038,-0.063 0.067,-0.037C0.109,0.002 0.168,0.012 0.34,0.012C0.614,0.012 0.678,-0.019 0.678,-0.154C0.678,-0.26 0.626,-0.3 0.489,-0.301C0.307,-0.302 0.276,-0.303 0.247,-0.305C0.219,-0.307 0.205,-0.317 0.205,-0.336C0.205,-0.366 0.239,-0.375 0.35,-0.375C0.41,-0.375 0.446,-0.371 0.461,-0.361C0.467,-0.357 0.469,-0.354 0.473,-0.339L0.653,-0.339C0.649,-0.396 0.64,-0.421 0.615,-0.441C0.575,-0.475 0.516,-0.485 0.355,-0.485C0.231,-0.485 0.148,-0.478 0.11,-0.463C0.054,-0.442 0.028,-0.398 0.028,-0.325C0.028,-0.224 0.084,-0.182 0.218,-0.18C0.392,-0.18 0.436,-0.179 0.46,-0.177C0.486,-0.174 0.501,-0.161 0.501,-0.142C0.501,-0.108 0.468,-0.098 0.353,-0.098C0.242,-0.098 0.208,-0.11 0.205,-0.151L0.025,-0.151Z" style={styleA} />
                        </g>
                        <g transform="matrix(111.719,0,0,111.719,565.875,1707.63)">
                            <path d="M0.04,-0.667L0.04,-0L0.217,-0L0.217,-0.202C0.217,-0.328 0.242,-0.353 0.367,-0.353C0.483,-0.353 0.504,-0.334 0.504,-0.231L0.504,-0L0.681,-0L0.681,-0.231C0.681,-0.342 0.67,-0.385 0.629,-0.427C0.588,-0.469 0.529,-0.486 0.424,-0.486C0.312,-0.486 0.254,-0.461 0.225,-0.401L0.217,-0.401L0.217,-0.667L0.04,-0.667Z" style={styleA} />
                        </g>
                        <g transform="matrix(111.719,0,0,111.719,674.019,1707.63)">
                            <path d="M0.047,-0.667L0.047,-0L0.26,-0L0.26,-0.241L0.719,-0.241L0.719,-0L0.932,-0L0.932,-0.667L0.719,-0.667L0.719,-0.428L0.26,-0.428L0.26,-0.667L0.047,-0.667Z" style={styleA} />
                        </g>
                        <g transform="matrix(111.719,0,0,111.719,783.392,1707.63)">
                            <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.473L0.04,-0.473ZM0.04,-0.667L0.04,-0.534L0.217,-0.534L0.217,-0.667L0.04,-0.667Z" style={styleA} />
                        </g>
                        <g transform="matrix(111.719,0,0,111.719,812.104,1707.63)">
                            <path d="M0.093,-0.582L0.093,-0.473L0,-0.473L0,-0.346L0.093,-0.346L0.093,-0.208C0.093,-0.169 0.093,-0.145 0.094,-0.137C0.096,-0.083 0.106,-0.057 0.131,-0.032C0.166,0.002 0.212,0.012 0.325,0.012C0.447,0.012 0.501,-0.003 0.537,-0.048C0.564,-0.083 0.571,-0.112 0.573,-0.202L0.411,-0.202C0.411,-0.153 0.41,-0.149 0.404,-0.138C0.396,-0.121 0.376,-0.114 0.34,-0.114C0.306,-0.114 0.288,-0.121 0.279,-0.137C0.271,-0.149 0.27,-0.156 0.27,-0.208L0.27,-0.346L0.55,-0.346L0.55,-0.473L0.27,-0.473L0.27,-0.582L0.093,-0.582Z" style={styleA} />
                        </g>
                        <g transform="matrix(111.719,0,0,111.719,876.789,1707.63)">
                            <path d="M0.025,-0.151C0.026,-0.096 0.038,-0.063 0.067,-0.037C0.109,0.002 0.168,0.012 0.34,0.012C0.614,0.012 0.678,-0.019 0.678,-0.154C0.678,-0.26 0.626,-0.3 0.489,-0.301C0.307,-0.302 0.276,-0.303 0.247,-0.305C0.219,-0.307 0.205,-0.317 0.205,-0.336C0.205,-0.366 0.239,-0.375 0.35,-0.375C0.41,-0.375 0.446,-0.371 0.461,-0.361C0.467,-0.357 0.469,-0.354 0.473,-0.339L0.653,-0.339C0.649,-0.396 0.64,-0.421 0.615,-0.441C0.575,-0.475 0.516,-0.485 0.355,-0.485C0.231,-0.485 0.148,-0.478 0.11,-0.463C0.054,-0.442 0.028,-0.398 0.028,-0.325C0.028,-0.224 0.084,-0.182 0.218,-0.18C0.392,-0.18 0.436,-0.179 0.46,-0.177C0.486,-0.174 0.501,-0.161 0.501,-0.142C0.501,-0.108 0.468,-0.098 0.353,-0.098C0.242,-0.098 0.208,-0.11 0.205,-0.151L0.025,-0.151Z" style={styleA} />
                        </g>
                    </g>
                    <g
                        ref={el => {link3 = el}}
                        onMouseEnter={() => mouseEnter3()}
                        onMouseLeave={() => mouseLeave1()}
                        onClick={() => props.linkLogic.handleClick3(true)}
                    >
                        <g transform="matrix(5.56226,0,0,1.59308,-8910.76,-968.084)">
                            <rect
                                // onClick={() => props.linkLogic.handleClick3(true)}
                                x="1708.77" y="1770.17" width="203.854" height="88.572" style={styleC}
                            />
                        </g>
                        <g
                            ref={el => {link3 = el}}
                            onClick={() => props.linkLogic.handleClick3(true)}
                            transform="matrix(0.437931,0,0,0.721425,281.999,607.33)"
                        >
                            <g transform="matrix(196.023,0,0,196.023,720.888,1868.72)">
                                <path d="M0.047,-0L0.528,-0C0.753,-0 0.812,-0.01 0.863,-0.056C0.912,-0.101 0.927,-0.169 0.927,-0.343C0.927,-0.452 0.923,-0.494 0.908,-0.541C0.888,-0.603 0.844,-0.641 0.778,-0.654C0.721,-0.666 0.698,-0.667 0.528,-0.667L0.047,-0.667L0.047,-0ZM0.247,-0.17L0.247,-0.5L0.528,-0.5C0.652,-0.5 0.679,-0.496 0.7,-0.472C0.72,-0.45 0.727,-0.413 0.727,-0.331C0.727,-0.18 0.714,-0.17 0.53,-0.17L0.247,-0.17Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,909.07,1868.72)">
                                <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1050.79,1868.72)">
                                <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.189C0.217,-0.276 0.221,-0.298 0.243,-0.321C0.264,-0.343 0.299,-0.352 0.364,-0.352C0.43,-0.352 0.464,-0.345 0.483,-0.326C0.502,-0.308 0.505,-0.291 0.505,-0.215L0.505,-0L0.682,-0L0.682,-0.215C0.682,-0.345 0.673,-0.381 0.63,-0.426C0.588,-0.469 0.53,-0.485 0.417,-0.485C0.302,-0.485 0.248,-0.462 0.216,-0.401L0.207,-0.401L0.207,-0.473L0.04,-0.473Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1191.73,1868.72)">
                                <path d="M0.266,-0.293L0.266,-0.491L0.211,-0.491L0.211,-0.51C0.211,-0.549 0.226,-0.565 0.262,-0.565L0.294,-0.565L0.294,-0.67C0.272,-0.671 0.26,-0.671 0.243,-0.671C0.136,-0.671 0.104,-0.663 0.079,-0.631C0.059,-0.606 0.053,-0.571 0.053,-0.487L0.053,-0.293L0.266,-0.293Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1257.21,1868.72)">
                                <path d="M0.093,-0.582L0.093,-0.473L0,-0.473L0,-0.346L0.093,-0.346L0.093,-0.208C0.093,-0.169 0.093,-0.145 0.094,-0.137C0.096,-0.083 0.106,-0.057 0.131,-0.032C0.166,0.002 0.212,0.012 0.325,0.012C0.447,0.012 0.501,-0.003 0.537,-0.048C0.564,-0.083 0.571,-0.112 0.573,-0.202L0.411,-0.202C0.411,-0.153 0.41,-0.149 0.404,-0.138C0.396,-0.121 0.376,-0.114 0.34,-0.114C0.306,-0.114 0.288,-0.121 0.279,-0.137C0.271,-0.149 0.27,-0.156 0.27,-0.208L0.27,-0.346L0.55,-0.346L0.55,-0.473L0.27,-0.473L0.27,-0.582L0.093,-0.582Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1419.71,1868.72)">
                                <path d="M0.047,-0.667L0.047,-0L0.25,-0L0.25,-0.231L0.727,-0.231L0.727,-0.384L0.25,-0.384L0.25,-0.51L0.752,-0.51L0.752,-0.667L0.047,-0.667Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1567.71,1868.72)">
                                <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1709.43,1868.72)">
                                <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.196C0.217,-0.269 0.222,-0.295 0.239,-0.318C0.256,-0.34 0.289,-0.352 0.329,-0.352C0.402,-0.352 0.422,-0.333 0.422,-0.263L0.589,-0.263L0.589,-0.295C0.589,-0.437 0.541,-0.485 0.398,-0.485C0.299,-0.485 0.249,-0.462 0.216,-0.402L0.207,-0.402L0.207,-0.473L0.04,-0.473Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1828.02,1868.72)">
                                <path d="M0.527,-0.473L0.527,-0.411L0.519,-0.411C0.482,-0.47 0.44,-0.485 0.317,-0.485C0.228,-0.485 0.162,-0.478 0.126,-0.463C0.052,-0.434 0.027,-0.381 0.027,-0.252C0.027,-0.133 0.041,-0.085 0.087,-0.049C0.128,-0.018 0.19,-0.006 0.315,-0.006C0.433,-0.006 0.475,-0.022 0.511,-0.08L0.518,-0.08L0.518,-0.038C0.518,0.028 0.515,0.043 0.498,0.062C0.477,0.086 0.443,0.093 0.35,0.093C0.257,0.093 0.231,0.084 0.221,0.048L0.219,0.043L0.039,0.043C0.044,0.1 0.059,0.136 0.091,0.164C0.136,0.203 0.197,0.216 0.347,0.216C0.459,0.216 0.526,0.209 0.578,0.191C0.625,0.175 0.663,0.138 0.679,0.093C0.693,0.054 0.694,0.041 0.694,-0.038L0.694,-0.473L0.527,-0.473ZM0.367,-0.352C0.419,-0.352 0.459,-0.347 0.48,-0.338C0.508,-0.325 0.517,-0.303 0.517,-0.246C0.517,-0.197 0.511,-0.177 0.492,-0.163C0.472,-0.148 0.427,-0.14 0.358,-0.14C0.23,-0.14 0.204,-0.158 0.204,-0.246C0.204,-0.335 0.23,-0.352 0.367,-0.352Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1971.71,1868.72)">
                                <path d="M0.487,-0.151C0.48,-0.109 0.459,-0.101 0.346,-0.101C0.282,-0.101 0.245,-0.107 0.226,-0.121C0.209,-0.134 0.204,-0.15 0.204,-0.198L0.666,-0.198C0.667,-0.218 0.667,-0.24 0.667,-0.246C0.667,-0.353 0.651,-0.406 0.609,-0.441C0.566,-0.476 0.51,-0.486 0.35,-0.486C0.196,-0.486 0.137,-0.476 0.091,-0.444C0.041,-0.409 0.027,-0.362 0.027,-0.235C0.027,-0.114 0.041,-0.067 0.087,-0.032C0.13,-0 0.195,0.012 0.332,0.012C0.585,0.012 0.65,-0.02 0.663,-0.151L0.487,-0.151ZM0.204,-0.295C0.206,-0.361 0.234,-0.376 0.353,-0.376C0.423,-0.376 0.459,-0.369 0.475,-0.352C0.486,-0.341 0.488,-0.332 0.489,-0.295L0.204,-0.295Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,2109.51,1868.72)">
                                <path d="M0.093,-0.582L0.093,-0.473L0,-0.473L0,-0.346L0.093,-0.346L0.093,-0.208C0.093,-0.169 0.093,-0.145 0.094,-0.137C0.096,-0.083 0.106,-0.057 0.131,-0.032C0.166,0.002 0.212,0.012 0.325,0.012C0.447,0.012 0.501,-0.003 0.537,-0.048C0.564,-0.083 0.571,-0.112 0.573,-0.202L0.411,-0.202C0.411,-0.153 0.41,-0.149 0.404,-0.138C0.396,-0.121 0.376,-0.114 0.34,-0.114C0.306,-0.114 0.288,-0.121 0.279,-0.137C0.271,-0.149 0.27,-0.156 0.27,-0.208L0.27,-0.346L0.55,-0.346L0.55,-0.473L0.27,-0.473L0.27,-0.582L0.093,-0.582Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,2272.02,1868.72)">
                                <path d="M0,-0.667L0,-0.497L0.297,-0.497L0.297,-0L0.51,-0L0.51,-0.497L0.807,-0.497L0.807,-0.667L0,-0.667Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,2404.92,1868.72)">
                                <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,2595.65,1868.72)">
                                <path d="M-0.013,-0.667L0.238,-0L0.553,-0L0.685,-0.489L0.819,-0L1.132,-0L1.391,-0.667L1.176,-0.667L0.996,-0.168L0.958,-0.168L0.806,-0.667L0.561,-0.667L0.41,-0.168L0.373,-0.168L0.194,-0.667L-0.013,-0.667Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,2862.04,1868.72)">
                                <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.196C0.217,-0.269 0.222,-0.295 0.239,-0.318C0.256,-0.34 0.289,-0.352 0.329,-0.352C0.402,-0.352 0.422,-0.333 0.422,-0.263L0.589,-0.263L0.589,-0.295C0.589,-0.437 0.541,-0.485 0.398,-0.485C0.299,-0.485 0.249,-0.462 0.216,-0.402L0.207,-0.402L0.207,-0.473L0.04,-0.473Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,2979.46,1868.72)">
                                <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.473L0.04,-0.473ZM0.04,-0.667L0.04,-0.534L0.217,-0.534L0.217,-0.667L0.04,-0.667Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,3029.84,1868.72)">
                                <path d="M0.093,-0.582L0.093,-0.473L0,-0.473L0,-0.346L0.093,-0.346L0.093,-0.208C0.093,-0.169 0.093,-0.145 0.094,-0.137C0.096,-0.083 0.106,-0.057 0.131,-0.032C0.166,0.002 0.212,0.012 0.325,0.012C0.447,0.012 0.501,-0.003 0.537,-0.048C0.564,-0.083 0.571,-0.112 0.573,-0.202L0.411,-0.202C0.411,-0.153 0.41,-0.149 0.404,-0.138C0.396,-0.121 0.376,-0.114 0.34,-0.114C0.306,-0.114 0.288,-0.121 0.279,-0.137C0.271,-0.149 0.27,-0.156 0.27,-0.208L0.27,-0.346L0.55,-0.346L0.55,-0.473L0.27,-0.473L0.27,-0.582L0.093,-0.582Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,3145.3,1868.72)">
                                <path d="M0.487,-0.151C0.48,-0.109 0.459,-0.101 0.346,-0.101C0.282,-0.101 0.245,-0.107 0.226,-0.121C0.209,-0.134 0.204,-0.15 0.204,-0.198L0.666,-0.198C0.667,-0.218 0.667,-0.24 0.667,-0.246C0.667,-0.353 0.651,-0.406 0.609,-0.441C0.566,-0.476 0.51,-0.486 0.35,-0.486C0.196,-0.486 0.137,-0.476 0.091,-0.444C0.041,-0.409 0.027,-0.362 0.027,-0.235C0.027,-0.114 0.041,-0.067 0.087,-0.032C0.13,-0 0.195,0.012 0.332,0.012C0.585,0.012 0.65,-0.02 0.663,-0.151L0.487,-0.151ZM0.204,-0.295C0.206,-0.361 0.234,-0.376 0.353,-0.376C0.423,-0.376 0.459,-0.369 0.475,-0.352C0.486,-0.341 0.488,-0.332 0.489,-0.295L0.204,-0.295Z" style={styleB} />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g transform="matrix(3.7551,0,0,1.51702,-5489.4,-858.004)">
                            <rect
                                onMouseEnter={() => mouseEnter2()}
                                onMouseLeave={() => mouseLeave1()}
                                onClick={() => props.linkLogic.handleClick2(true)}
                                x="1620" y="1694.09" width="201.66" height="83.868" style={styleC} />
                        </g>
                        <g
                            ref={el => {link2 = el}}
                            onClick={() => props.linkLogic.handleClick2(true)}
                            transform="matrix(0.437931,0,0,0.721425,281.59,469.192)"
                        >
                            <g transform="matrix(196.023,0,0,196.023,720.888,1868.72)">
                                <path d="M0.047,-0.667L0.047,-0L0.734,-0L0.734,-0.17L0.26,-0.17L0.26,-0.667L0.047,-0.667Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,864.768,1868.72)">
                                <path d="M0.221,-0.325C0.226,-0.368 0.239,-0.372 0.366,-0.372C0.473,-0.372 0.487,-0.362 0.487,-0.288L0.487,-0.263L0.479,-0.263C0.451,-0.293 0.427,-0.3 0.345,-0.3C0.215,-0.3 0.169,-0.297 0.126,-0.285C0.057,-0.267 0.025,-0.22 0.025,-0.135C0.025,-0.023 0.074,0.012 0.231,0.012C0.346,0.012 0.391,0.008 0.42,-0.004C0.452,-0.017 0.471,-0.034 0.486,-0.065L0.493,-0.065L0.493,-0L0.663,-0L0.663,-0.285C0.663,-0.371 0.653,-0.407 0.623,-0.439C0.583,-0.479 0.547,-0.486 0.392,-0.486C0.25,-0.486 0.168,-0.477 0.127,-0.458C0.072,-0.431 0.049,-0.394 0.044,-0.325L0.221,-0.325ZM0.339,-0.2C0.414,-0.2 0.442,-0.199 0.453,-0.195C0.473,-0.188 0.484,-0.174 0.484,-0.155C0.484,-0.113 0.449,-0.101 0.324,-0.101C0.229,-0.101 0.202,-0.112 0.202,-0.15C0.202,-0.189 0.231,-0.2 0.339,-0.2Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1001.98,1868.72)">
                                <path d="M0.04,-0.667L0.04,-0L0.207,-0L0.207,-0.062L0.216,-0.062C0.251,-0.003 0.296,0.012 0.428,0.012C0.55,0.012 0.616,-0.002 0.655,-0.037C0.695,-0.072 0.707,-0.12 0.707,-0.238C0.707,-0.361 0.693,-0.407 0.647,-0.442C0.606,-0.473 0.54,-0.486 0.423,-0.486C0.305,-0.486 0.26,-0.469 0.225,-0.41L0.217,-0.41L0.217,-0.667L0.04,-0.667ZM0.374,-0.353C0.422,-0.353 0.465,-0.348 0.488,-0.34C0.52,-0.327 0.53,-0.305 0.53,-0.242C0.53,-0.183 0.523,-0.157 0.503,-0.143C0.484,-0.129 0.441,-0.122 0.377,-0.122C0.33,-0.122 0.285,-0.127 0.263,-0.134C0.227,-0.146 0.217,-0.168 0.217,-0.235C0.217,-0.296 0.225,-0.32 0.25,-0.335C0.27,-0.347 0.312,-0.353 0.374,-0.353Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1145.86,1868.72)">
                                <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1287.59,1868.72)">
                                <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.196C0.217,-0.269 0.222,-0.295 0.239,-0.318C0.256,-0.34 0.289,-0.352 0.329,-0.352C0.402,-0.352 0.422,-0.333 0.422,-0.263L0.589,-0.263L0.589,-0.295C0.589,-0.437 0.541,-0.485 0.398,-0.485C0.299,-0.485 0.249,-0.462 0.216,-0.402L0.207,-0.402L0.207,-0.473L0.04,-0.473Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1406.18,1868.72)">
                                <path d="M0.025,-0.151C0.026,-0.096 0.038,-0.063 0.067,-0.037C0.109,0.002 0.168,0.012 0.34,0.012C0.614,0.012 0.678,-0.019 0.678,-0.154C0.678,-0.26 0.626,-0.3 0.489,-0.301C0.307,-0.302 0.276,-0.303 0.247,-0.305C0.219,-0.307 0.205,-0.317 0.205,-0.336C0.205,-0.366 0.239,-0.375 0.35,-0.375C0.41,-0.375 0.446,-0.371 0.461,-0.361C0.467,-0.357 0.469,-0.354 0.473,-0.339L0.653,-0.339C0.649,-0.396 0.64,-0.421 0.615,-0.441C0.575,-0.475 0.516,-0.485 0.355,-0.485C0.231,-0.485 0.148,-0.478 0.11,-0.463C0.054,-0.442 0.028,-0.398 0.028,-0.325C0.028,-0.224 0.084,-0.182 0.218,-0.18C0.392,-0.18 0.436,-0.179 0.46,-0.177C0.486,-0.174 0.501,-0.161 0.501,-0.142C0.501,-0.108 0.468,-0.098 0.353,-0.098C0.242,-0.098 0.208,-0.11 0.205,-0.151L0.025,-0.151Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1592.99,1868.72)">
                                <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1734.72,1868.72)">
                                <path d="M0,-0.346L0.091,-0.346L0.091,-0L0.268,-0L0.268,-0.346L0.442,-0.346L0.442,-0.473L0.268,-0.473C0.27,-0.547 0.287,-0.56 0.381,-0.56C0.392,-0.56 0.395,-0.56 0.428,-0.559L0.428,-0.676C0.39,-0.678 0.372,-0.679 0.339,-0.679C0.221,-0.679 0.176,-0.669 0.138,-0.634C0.102,-0.601 0.094,-0.574 0.091,-0.473L0,-0.473L0,-0.346Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1868.99,1868.72)">
                                <path d="M0.047,-0.667L0.047,-0L0.734,-0L0.734,-0.17L0.26,-0.17L0.26,-0.667L0.047,-0.667Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,2012.87,1868.72)">
                                <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,2153.62,1868.72)">
                                <path d="M-0.013,-0.473L0.22,-0L0.449,-0L0.682,-0.473L0.481,-0.473L0.335,-0.137L0.188,-0.473L-0.013,-0.473Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,2283.38,1868.72)">
                                <path d="M0.487,-0.151C0.48,-0.109 0.459,-0.101 0.346,-0.101C0.282,-0.101 0.245,-0.107 0.226,-0.121C0.209,-0.134 0.204,-0.15 0.204,-0.198L0.666,-0.198C0.667,-0.218 0.667,-0.24 0.667,-0.246C0.667,-0.353 0.651,-0.406 0.609,-0.441C0.566,-0.476 0.51,-0.486 0.35,-0.486C0.196,-0.486 0.137,-0.476 0.091,-0.444C0.041,-0.409 0.027,-0.362 0.027,-0.235C0.027,-0.114 0.041,-0.067 0.087,-0.032C0.13,-0 0.195,0.012 0.332,0.012C0.585,0.012 0.65,-0.02 0.663,-0.151L0.487,-0.151ZM0.204,-0.295C0.206,-0.361 0.234,-0.376 0.353,-0.376C0.423,-0.376 0.459,-0.369 0.475,-0.352C0.486,-0.341 0.488,-0.332 0.489,-0.295L0.204,-0.295Z" style={styleB} />
                            </g>
                        </g>
                    </g>
                    <g>
                        <path
                            id="testing"
                            onMouseEnter={() => mouseEnter1()}
                            onMouseLeave={(e) => mouseLeave1(e)}
                            onClick={() => {
                                props.linkLogic.handleClick1(true)
                            }
                            }
                        
                        
                        d="M593.845,1451.42L593.845,1694.09L1204.54,1694.09L1204.54,1571.73L1970.57,1571.73L1970.57,1451.42L593.845,1451.42Z" style={styleC} />
                        <g
                            transform="matrix(0.437931,0,0,0.721425,287.149,347.449)"
                            ref={el => {link1 = el}}
                            onClick={() => {
                                props.linkLogic.handleClick1(true)
                            }
                        }
                        >
                            <g transform="matrix(196.023,0,0,196.023,720.888,1677.24)">
                                <path d="M-0.013,-0.667L0.238,-0L0.553,-0L0.685,-0.489L0.819,-0L1.132,-0L1.391,-0.667L1.176,-0.667L0.996,-0.168L0.958,-0.168L0.806,-0.667L0.561,-0.667L0.41,-0.168L0.373,-0.168L0.194,-0.667L-0.013,-0.667Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,983.754,1677.24)">
                                <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1125.48,1677.24)">
                                <path d="M0.678,-0L0.678,-0.473L0.501,-0.473L0.501,-0.283C0.501,-0.195 0.495,-0.17 0.47,-0.148C0.448,-0.129 0.416,-0.122 0.353,-0.122C0.286,-0.122 0.259,-0.128 0.238,-0.148C0.219,-0.166 0.214,-0.188 0.214,-0.257L0.214,-0.473L0.037,-0.473L0.037,-0.257C0.037,-0.128 0.046,-0.092 0.089,-0.048C0.132,-0.005 0.19,0.012 0.3,0.012C0.419,0.012 0.475,-0.011 0.502,-0.071L0.511,-0.071L0.511,-0L0.678,-0Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1266.03,1677.24)">
                                <rect x="0.04" y="-0.667" width="0.177" height="0.667" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1316.4,1677.24)">
                                <path d="M0.693,-0.667L0.516,-0.667L0.516,-0.41L0.509,-0.41C0.475,-0.47 0.434,-0.486 0.31,-0.486C0.188,-0.486 0.139,-0.478 0.096,-0.45C0.046,-0.417 0.027,-0.36 0.027,-0.24C0.027,-0.122 0.039,-0.074 0.078,-0.038C0.119,-0.001 0.18,0.012 0.308,0.012C0.436,0.012 0.483,-0.005 0.517,-0.062L0.526,-0.062L0.526,-0L0.693,-0L0.693,-0.667ZM0.368,-0.353C0.494,-0.353 0.517,-0.335 0.517,-0.237C0.517,-0.182 0.51,-0.157 0.49,-0.144C0.47,-0.13 0.422,-0.122 0.357,-0.122C0.224,-0.122 0.204,-0.138 0.204,-0.242C0.204,-0.337 0.228,-0.353 0.368,-0.353Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1460.09,1677.24)">
                                <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.189C0.217,-0.276 0.221,-0.298 0.243,-0.321C0.264,-0.343 0.299,-0.352 0.364,-0.352C0.43,-0.352 0.464,-0.345 0.483,-0.326C0.502,-0.308 0.505,-0.291 0.505,-0.215L0.505,-0L0.682,-0L0.682,-0.215C0.682,-0.345 0.673,-0.381 0.63,-0.426C0.588,-0.469 0.53,-0.485 0.417,-0.485C0.302,-0.485 0.248,-0.462 0.216,-0.401L0.207,-0.401L0.207,-0.473L0.04,-0.473Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1600.25,1677.24)">
                                <path d="M0.068,-0.667L0.068,-0.469L0.123,-0.469L0.123,-0.45C0.123,-0.411 0.108,-0.395 0.073,-0.395L0.04,-0.395L0.04,-0.29C0.062,-0.289 0.074,-0.289 0.091,-0.289C0.199,-0.289 0.229,-0.297 0.255,-0.329C0.275,-0.354 0.281,-0.389 0.281,-0.473L0.281,-0.667L0.068,-0.667Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1666.31,1677.24)">
                                <path d="M0.093,-0.582L0.093,-0.473L0,-0.473L0,-0.346L0.093,-0.346L0.093,-0.208C0.093,-0.169 0.093,-0.145 0.094,-0.137C0.096,-0.083 0.106,-0.057 0.131,-0.032C0.166,0.002 0.212,0.012 0.325,0.012C0.447,0.012 0.501,-0.003 0.537,-0.048C0.564,-0.083 0.571,-0.112 0.573,-0.202L0.411,-0.202C0.411,-0.153 0.41,-0.149 0.404,-0.138C0.396,-0.121 0.376,-0.114 0.34,-0.114C0.306,-0.114 0.288,-0.121 0.279,-0.137C0.271,-0.149 0.27,-0.156 0.27,-0.208L0.27,-0.346L0.55,-0.346L0.55,-0.473L0.27,-0.473L0.27,-0.582L0.093,-0.582Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1828.81,1677.24)">
                                <path d="M-0.02,-0.667L0.362,-0.236L0.362,-0L0.572,-0L0.572,-0.236L0.937,-0.667L0.672,-0.667L0.464,-0.4L0.247,-0.667L-0.02,-0.667Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1990.72,1677.24)">
                                <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,2132.45,1677.24)">
                                <path d="M0.678,-0L0.678,-0.473L0.501,-0.473L0.501,-0.283C0.501,-0.195 0.495,-0.17 0.47,-0.148C0.448,-0.129 0.416,-0.122 0.353,-0.122C0.286,-0.122 0.259,-0.128 0.238,-0.148C0.219,-0.166 0.214,-0.188 0.214,-0.257L0.214,-0.473L0.037,-0.473L0.037,-0.257C0.037,-0.128 0.046,-0.092 0.089,-0.048C0.132,-0.005 0.19,0.012 0.3,0.012C0.419,0.012 0.475,-0.011 0.502,-0.071L0.511,-0.071L0.511,-0L0.678,-0Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,2322,1677.24)">
                                <path d="M0.047,-0.667L0.047,-0L0.734,-0L0.734,-0.17L0.26,-0.17L0.26,-0.667L0.047,-0.667Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,2465.88,1677.24)">
                                <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.473L0.04,-0.473ZM0.04,-0.667L0.04,-0.534L0.217,-0.534L0.217,-0.667L0.04,-0.667Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,2516.26,1677.24)">
                                <path d="M0.04,-0.667L0.04,-0L0.217,-0L0.217,-0.19L0.262,-0.19L0.452,-0L0.687,-0L0.409,-0.253L0.654,-0.473L0.417,-0.473L0.255,-0.319L0.217,-0.319L0.217,-0.667L0.04,-0.667Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,2643.48,1677.24)">
                                <path d="M0.487,-0.151C0.48,-0.109 0.459,-0.101 0.346,-0.101C0.282,-0.101 0.245,-0.107 0.226,-0.121C0.209,-0.134 0.204,-0.15 0.204,-0.198L0.666,-0.198C0.667,-0.218 0.667,-0.24 0.667,-0.246C0.667,-0.353 0.651,-0.406 0.609,-0.441C0.566,-0.476 0.51,-0.486 0.35,-0.486C0.196,-0.486 0.137,-0.476 0.091,-0.444C0.041,-0.409 0.027,-0.362 0.027,-0.235C0.027,-0.114 0.041,-0.067 0.087,-0.032C0.13,-0 0.195,0.012 0.332,0.012C0.585,0.012 0.65,-0.02 0.663,-0.151L0.487,-0.151ZM0.204,-0.295C0.206,-0.361 0.234,-0.376 0.353,-0.376C0.423,-0.376 0.459,-0.369 0.475,-0.352C0.486,-0.341 0.488,-0.332 0.489,-0.295L0.204,-0.295Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,2828.33,1677.24)">
                                <path d="M0,-0.667L0,-0.497L0.297,-0.497L0.297,-0L0.51,-0L0.51,-0.497L0.807,-0.497L0.807,-0.667L0,-0.667Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,2961.23,1677.24)">
                                <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,3151.96,1677.24)">
                                <path d="M0.047,-0.667L0.047,-0L0.25,-0L0.25,-0.255L0.361,-0.255L0.66,-0L0.968,-0L0.55,-0.34L0.936,-0.671L0.625,-0.671L0.361,-0.425L0.25,-0.425L0.25,-0.667L0.047,-0.667Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,3337.79,1677.24)">
                                <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.189C0.217,-0.276 0.221,-0.298 0.243,-0.321C0.264,-0.343 0.299,-0.352 0.364,-0.352C0.43,-0.352 0.464,-0.345 0.483,-0.326C0.502,-0.308 0.505,-0.291 0.505,-0.215L0.505,-0L0.682,-0L0.682,-0.215C0.682,-0.345 0.673,-0.381 0.63,-0.426C0.588,-0.469 0.53,-0.485 0.417,-0.485C0.302,-0.485 0.248,-0.462 0.216,-0.401L0.207,-0.401L0.207,-0.473L0.04,-0.473Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,3478.73,1677.24)">
                                <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,3619.87,1677.24)">
                                <path d="M-0.01,-0.473L0.165,-0L0.401,-0L0.496,-0.278L0.589,-0L0.83,-0L1.005,-0.473L0.817,-0.473L0.706,-0.135L0.579,-0.473L0.411,-0.473L0.285,-0.129L0.177,-0.473L-0.01,-0.473Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,720.888,1846)">
                                <path d="M0.047,-0.667L0.047,-0L0.247,-0L0.242,-0.499L0.278,-0.499L0.529,-0L0.698,-0L0.949,-0.499L0.983,-0.499L0.978,-0L1.178,-0L1.178,-0.667L0.812,-0.667L0.613,-0.234L0.415,-0.667L0.047,-0.667Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,960.82,1846)">
                                <path d="M0.487,-0.151C0.48,-0.109 0.459,-0.101 0.346,-0.101C0.282,-0.101 0.245,-0.107 0.226,-0.121C0.209,-0.134 0.204,-0.15 0.204,-0.198L0.666,-0.198C0.667,-0.218 0.667,-0.24 0.667,-0.246C0.667,-0.353 0.651,-0.406 0.609,-0.441C0.566,-0.476 0.51,-0.486 0.35,-0.486C0.196,-0.486 0.137,-0.476 0.091,-0.444C0.041,-0.409 0.027,-0.362 0.027,-0.235C0.027,-0.114 0.041,-0.067 0.087,-0.032C0.13,-0 0.195,0.012 0.332,0.012C0.585,0.012 0.65,-0.02 0.663,-0.151L0.487,-0.151ZM0.204,-0.295C0.206,-0.361 0.234,-0.376 0.353,-0.376C0.423,-0.376 0.459,-0.369 0.475,-0.352C0.486,-0.341 0.488,-0.332 0.489,-0.295L0.204,-0.295Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1145.67,1846)">
                                <path d="M0.047,-0L0.527,-0C0.751,-0.001 0.762,-0.002 0.8,-0.015C0.859,-0.035 0.884,-0.084 0.884,-0.179C0.884,-0.286 0.857,-0.325 0.769,-0.34C0.836,-0.359 0.859,-0.398 0.859,-0.493C0.859,-0.614 0.823,-0.656 0.714,-0.664C0.684,-0.666 0.684,-0.666 0.526,-0.667L0.047,-0.667L0.047,-0ZM0.24,-0.404L0.24,-0.51L0.526,-0.51C0.618,-0.509 0.621,-0.509 0.632,-0.507C0.653,-0.502 0.662,-0.487 0.662,-0.459C0.662,-0.435 0.655,-0.418 0.64,-0.412C0.627,-0.406 0.598,-0.404 0.526,-0.404L0.24,-0.404ZM0.24,-0.16L0.24,-0.271L0.527,-0.271C0.645,-0.27 0.645,-0.27 0.655,-0.268C0.678,-0.263 0.687,-0.249 0.687,-0.218C0.687,-0.19 0.68,-0.175 0.663,-0.168C0.648,-0.161 0.648,-0.161 0.527,-0.16L0.24,-0.16Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1323.66,1846)">
                                <path d="M0.487,-0.151C0.48,-0.109 0.459,-0.101 0.346,-0.101C0.282,-0.101 0.245,-0.107 0.226,-0.121C0.209,-0.134 0.204,-0.15 0.204,-0.198L0.666,-0.198C0.667,-0.218 0.667,-0.24 0.667,-0.246C0.667,-0.353 0.651,-0.406 0.609,-0.441C0.566,-0.476 0.51,-0.486 0.35,-0.486C0.196,-0.486 0.137,-0.476 0.091,-0.444C0.041,-0.409 0.027,-0.362 0.027,-0.235C0.027,-0.114 0.041,-0.067 0.087,-0.032C0.13,-0 0.195,0.012 0.332,0.012C0.585,0.012 0.65,-0.02 0.663,-0.151L0.487,-0.151ZM0.204,-0.295C0.206,-0.361 0.234,-0.376 0.353,-0.376C0.423,-0.376 0.459,-0.369 0.475,-0.352C0.486,-0.341 0.488,-0.332 0.489,-0.295L0.204,-0.295Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1461.46,1846)">
                                <path d="M0.093,-0.582L0.093,-0.473L0,-0.473L0,-0.346L0.093,-0.346L0.093,-0.208C0.093,-0.169 0.093,-0.145 0.094,-0.137C0.096,-0.083 0.106,-0.057 0.131,-0.032C0.166,0.002 0.212,0.012 0.325,0.012C0.447,0.012 0.501,-0.003 0.537,-0.048C0.564,-0.083 0.571,-0.112 0.573,-0.202L0.411,-0.202C0.411,-0.153 0.41,-0.149 0.404,-0.138C0.396,-0.121 0.376,-0.114 0.34,-0.114C0.306,-0.114 0.288,-0.121 0.279,-0.137C0.271,-0.149 0.27,-0.156 0.27,-0.208L0.27,-0.346L0.55,-0.346L0.55,-0.473L0.27,-0.473L0.27,-0.582L0.093,-0.582Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1574.96,1846)">
                                <path d="M0.093,-0.582L0.093,-0.473L0,-0.473L0,-0.346L0.093,-0.346L0.093,-0.208C0.093,-0.169 0.093,-0.145 0.094,-0.137C0.096,-0.083 0.106,-0.057 0.131,-0.032C0.166,0.002 0.212,0.012 0.325,0.012C0.447,0.012 0.501,-0.003 0.537,-0.048C0.564,-0.083 0.571,-0.112 0.573,-0.202L0.411,-0.202C0.411,-0.153 0.41,-0.149 0.404,-0.138C0.396,-0.121 0.376,-0.114 0.34,-0.114C0.306,-0.114 0.288,-0.121 0.279,-0.137C0.271,-0.149 0.27,-0.156 0.27,-0.208L0.27,-0.346L0.55,-0.346L0.55,-0.473L0.27,-0.473L0.27,-0.582L0.093,-0.582Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1690.42,1846)">
                                <path d="M0.487,-0.151C0.48,-0.109 0.459,-0.101 0.346,-0.101C0.282,-0.101 0.245,-0.107 0.226,-0.121C0.209,-0.134 0.204,-0.15 0.204,-0.198L0.666,-0.198C0.667,-0.218 0.667,-0.24 0.667,-0.246C0.667,-0.353 0.651,-0.406 0.609,-0.441C0.566,-0.476 0.51,-0.486 0.35,-0.486C0.196,-0.486 0.137,-0.476 0.091,-0.444C0.041,-0.409 0.027,-0.362 0.027,-0.235C0.027,-0.114 0.041,-0.067 0.087,-0.032C0.13,-0 0.195,0.012 0.332,0.012C0.585,0.012 0.65,-0.02 0.663,-0.151L0.487,-0.151ZM0.204,-0.295C0.206,-0.361 0.234,-0.376 0.353,-0.376C0.423,-0.376 0.459,-0.369 0.475,-0.352C0.486,-0.341 0.488,-0.332 0.489,-0.295L0.204,-0.295Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1826.26,1846)">
                                <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.196C0.217,-0.269 0.222,-0.295 0.239,-0.318C0.256,-0.34 0.289,-0.352 0.329,-0.352C0.402,-0.352 0.422,-0.333 0.422,-0.263L0.589,-0.263L0.589,-0.295C0.589,-0.437 0.541,-0.485 0.398,-0.485C0.299,-0.485 0.249,-0.462 0.216,-0.402L0.207,-0.402L0.207,-0.473L0.04,-0.473Z" style={styleB} />
                            </g>
                            <g transform="matrix(196.023,0,0,196.023,1943.09,1846)">
                                <path d="M0.188,-0.428L0.189,-0.443C0.19,-0.505 0.193,-0.516 0.214,-0.527C0.235,-0.538 0.249,-0.539 0.356,-0.539C0.429,-0.539 0.456,-0.538 0.478,-0.532C0.504,-0.526 0.516,-0.51 0.516,-0.481C0.516,-0.441 0.495,-0.426 0.419,-0.414C0.322,-0.399 0.313,-0.397 0.29,-0.387C0.241,-0.367 0.225,-0.334 0.225,-0.252L0.225,-0.226L0.4,-0.226C0.399,-0.258 0.404,-0.262 0.459,-0.272C0.544,-0.286 0.569,-0.291 0.6,-0.301C0.666,-0.322 0.689,-0.366 0.689,-0.474C0.689,-0.644 0.637,-0.677 0.372,-0.677C0.166,-0.677 0.082,-0.659 0.041,-0.606C0.02,-0.578 0.013,-0.542 0.013,-0.463L0.013,-0.428L0.188,-0.428ZM0.207,-0.178L0.207,-0L0.423,-0L0.423,-0.178L0.207,-0.178Z" style={styleB} />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    )
}