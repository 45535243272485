import React, {useState, useEffect, useRef, useLayoutEffect} from "react"
import {Link, navigate} from 'gatsby'
import { gsap } from "gsap";
import {Header} from '../svg/header'
import {Title} from '../svg/title'
import Menu from '../modules/menu'
import {Footer} from '../svg/footer'
import photo from '../../public/port-pic.png'
import {settings} from '../storage'
import eyeball from '../modules/eyeball'

export default function Home(){

  const [edge, setEdge] = useState(null)
  const [load, setLoad] = useState(false)
  const [stageBuilt, setStageBuilt] = useState(false)
  const [reverseComplete, setReverseComplete] = useState(false)
  const size = useWindowSize()
  let recordContainer = useRef(null)
  let record = useRef(null)
  let recordBack = useRef(null)
  let leftEyeContainer = useRef(null)
  let leftEye = useRef(null)
  let rightEyeContainer = useRef(null)
  let rightEye = useRef(null)
  let leftInnerMarker = useRef(null)
  let leftOuterMarker = useRef(null)
  let page = useRef(null)
  let menu = useRef(null)

  // let vh = window.innerHeight * 0.01;
  // document.documentElement.style.setProperty('--vh', `${vh}px`);


  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
  }

  //this needs to be edited, right now load is set to true only is an edge is defined, load needs to be set to true once the images are loaded
  useEffect(() => {
    if (edge !== null && load === false){
      setLoad(true)
    }
  })

  useEffect(() => {
    // document.documentElement.style.setProperty('--vh', `${vh}px`);
    if (size.height <= size.width){
      setEdge(size.height)
    }
    else {
      setEdge(size.width)
    }
  })

  const establishEyeballs = () => {
    eyeball(page, leftEyeContainer, leftEye, leftInnerMarker, leftOuterMarker)
    eyeball(page, rightEyeContainer, rightEye, leftInnerMarker, leftOuterMarker)
  }

  const stageBuild = () => {

    let speed = 3
    let rotation = '900deg'
    let ease = 'Power4.easeOut'


    const tl = gsap.timeline()
          tl.from([record], speed, {ease: ease, scale: 0.1, rotateX: rotation}, 0)
          tl.from([recordContainer], speed, {ease: ease, scale: 0.1, autoAlpha: 0, rotateX: rotation, onComplete: () => {
            establishEyeballs()
            setStageBuilt(true)
            }}, 0)

    // gsap.set(recordContainer, {autoAlpha: 1})

    // eyeball(page, leftEyeContainer, leftEye, leftInnerMarker, leftOuterMarker)
    // eyeball(page, rightEyeContainer, rightEye, leftInnerMarker, leftOuterMarker)


  }

  const reverseFlip = () => {

    gsap.set(recordBack, {backgroundColor: settings.color})
    gsap.set(record, {rotateY: '+=180deg'})
    gsap.set(recordContainer, {autoAlpha: 1, width: window.innerWidth, height: window.innerHeight})

    const tl = gsap.timeline()
    tl.to([record, recordContainer], 0.5, {ease: 'Power1.easeOut', height: edge, width: edge, scale: 0.75}, 0)
    tl.to(record, 0.5, {ease: 'Power1.easeOut', rotateY: '+=180deg'}, '-=.25')
    tl.to([recordContainer, record], 0.5, {ease: 'Power1.easeOut', scale: 1, onComplete: () => {
      gsap.set(record, {height: '100%', width: '100%'})
      establishEyeballs()
      setReverseComplete(true)
    }}, '=0')
  }

  //handle page load event
  useEffect(() => {
    if (!settings.reversePageTransition){
      if (load){
        stageBuild()
      }
    }
    else {
      if (load){
        reverseFlip()
      }
    }
  }, [load])

  let linkLogic = {
    handleClick1(fromHome){

      settings.color = '#017BB3'
      gsap.set(recordBack, {backgroundColor: '#017BB3'})

      if (fromHome){
        gsap.to(menu, .5, {ease: 'Power4.easeOut', autoAlpha: 0})
      }

      const tl = gsap.timeline()
      tl.to(record, 0.5, {ease: 'Power1.easeOut', scale: 0.75})
        .to(record, 0.5, {ease: 'Power1.easeOut', rotateY: '+=180deg'}, "-=.25")
        .to([record, recordContainer], 0.5, {ease: 'Power1.easeOut', scale: 1, height: '100%', width: '100%', onComplete: () => {settings.reversePageTransition = true; navigate('/about')}}, "=0")

    },
    handleClick2(fromHome){

      settings.color = '#EFA226'
      gsap.set(recordBack, {backgroundColor: '#EFA226'})

      if (fromHome){
        gsap.to(menu, .5, {ease: 'Power4.easeOut', autoAlpha: 0})
      }

      const tl = gsap.timeline()
      tl.to(record, .5, {ease: 'Power1.easeOut', scale: 0.75})
        .to(record, .5, {ease: 'Power1.easeOut', rotateY: '+=180deg'}, "-=.25")
        .to([record, recordContainer], .5, {ease: 'Power1.easeOut', scale: 1, height: '100%', width: '100%', onComplete: () => {settings.reversePageTransition = true; navigate('/work')}}, "=0")
    },
    handleClick3(fromHome){

      settings.color = '#000000'
      gsap.set(recordBack, {backgroundColor: '#000000'})

      if (fromHome){
        gsap.to(menu, .5, {ease: 'Power4.easeOut', autoAlpha: 0})
      }
      const tl = gsap.timeline()
      tl.to(record, .5, {ease: 'Power1.easeOut', scale: 0.75})
        .to(record, .5, {ease: 'Power1.easeOut', rotateY: '+=180deg'}, "-=.25")
        .to([record, recordContainer], .5, {ease: 'Power1.easeOut', scale: 1, height: '100%', width: '100%', onComplete: () => {settings.reversePageTransition = true; navigate('/contact')}}, "=0")
    }
  }

    return (
      <div className="app">
        <div ref={el => {menu = el}}>
        <Menu fromHome={true} linkLogic={linkLogic} stageBuilt={stageBuilt} reverseComplete={reverseComplete} page="home" />
        </div>
        <div id="home" className="page_defaults" ref={el => {page = el}}>

          {
            !load ? null :
            <div
            id="record_container"
            style={{height: edge, width: edge}}
            ref={el => {recordContainer = el}}
            >
              <div
              id="record"
              ref={el => {record = el}}
              >
                <div id="record_front">
                  <div id="record_overlay" />

                  <div id="record_header">
                    <Link
                    className="link_style"
                    to="/work"
                    >
                    <Header />
                    </Link>
                  </div>

                  <div id="record_title">
                    <Title />
                  </div>

                  <div id="record_body">
                    <div id="accent_container">
                      <div className="accent" />
                      <div className="accent" />
                      <div className="accent" />
                    </div>
                    <div id="left_inner_marker" ref={el => {leftInnerMarker = el}} />
                      <div id="left_outer_marker" ref={el => {leftOuterMarker = el}} />
                    <div ref={el => {leftEyeContainer = el}} id="left_eye_container" className="eye_container">
                      <div id="x_axis" />
                      <div id="y_axis" />
                      <div ref={el => {leftEye = el}} id="left_eye" className="eye" />
                    </div>
                    <div ref={el => {rightEyeContainer = el}} id="right_eye_container" className="eye_container">
                      <div ref={el => {rightEye = el}} id="right_eye" className="eye" />
                    </div>
                    <img id="port" src={photo} />
                  </div>

                  <div id="record_footer">
                    <Footer linkLogic={linkLogic} />
                    {/* <div id="inc_svg_container">
                      <IncludingSVG />
                    </div>
                    <div id="svg_link_container">
                      <div id="about_svg"><AboutSVG /></div>
                      <div id="work_svg"><WorkSVG /></div>
                      <div id="contact_svg"><PortfolioSVG /></div>
                    </div> */}
                  </div>
                </div>
                <div
                 id="record_back"
                 ref={el => {recordBack = el}}
                 />
              </div>
            </div>
          }
        </div>
      </div>
    )

}
